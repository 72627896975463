import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.search.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import SocialService from "@/services/SocialService";
export default {
  name: "Messages",
  components: {
    Loading: function Loading() {
      return import("@/components/Loading");
    }
  },
  props: {
    id: String
  },
  data: function data() {
    return {
      totalMessages: 0,
      totalPages: 0,
      messages: [],
      loading: true,
      options: {
        itemsPerPage: 5,
        page: 1
      },
      dialog: {//
      },
      search: null,
      filters: {//
      }
    };
  },
  mounted: function mounted() {
    this.getDataFromApi();
  },
  watch: {
    options: {
      handler: function handler() {
        this.getDataFromApi();
      },
      deep: true
    }
  },
  computed: {//
  },
  methods: {
    updatePage: function updatePage(event) {
      console.log("update:page");
      console.log(event);
    },
    updateSortBy: function updateSortBy(event) {
      console.log("update:sortBy");
      console.log(event);
    },
    updateOptions: function updateOptions(event) {
      console.log("update:options");
      console.log(event);
    },
    updateSearch: function updateSearch() {
      this.getDataFromApi();
    },
    clearSearch: function clearSearch() {
      this.search = null;
      this.getDataFromApi();
    },
    getDataFromApi: function getDataFromApi() {
      var _this = this;

      this.loading = true;
      SocialService.getMessageList(this.id, {
        pageNumber: this.options.page,
        pageSize: this.options.itemsPerPage,
        search: this.search
      }).then(function (result) {
        _this.messages = result.data.data;
        _this.totalMessages = result.data.totalRecords;
        _this.totalPages = result.data.totalPages;
      }).finally(function () {
        _this.loading = false;
      });
    }
  }
};